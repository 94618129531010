/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Directory = {
  id: string;
  name: string;
  user_id: number;
  dir_status: number;
  is_archieved: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};

type DirectoryState = {
  directories: Directory[];
  loading: boolean;
  error: string | null;
};

const initialState: DirectoryState = {
  directories: [],
  loading: false,
  error: null,
};

const directorySlice = createSlice({
  name: 'directory',
  initialState,
  reducers: {
    setDirectories(state: DirectoryState, action: PayloadAction<Directory[]>) {
      state.directories = action.payload;
      state.loading = false;
      state.error = null;
    },
    addDirectory(state: DirectoryState, action: PayloadAction<Directory>) {
      state.directories.push(action.payload);
    },
    updateDirectory(state: DirectoryState, action: PayloadAction<Directory>) {
      const updatedDirectory = action.payload;
      const index = state.directories.findIndex(
        (directory) => directory.id === updatedDirectory.id,
      );
      if (index !== -1) {
        state.directories[index] = updatedDirectory;
      }
    },
    deleteDirectory(state: DirectoryState, action: PayloadAction<string>) {
      const directoryId = action.payload;
      state.directories = state.directories.filter(
        (directory) => directory.id !== directoryId,
      );
    },
    setLoading(state: DirectoryState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state: DirectoryState, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
  },
});

export const {
  setDirectories,
  addDirectory,
  updateDirectory,
  deleteDirectory,
  setLoading,
  setError,
} = directorySlice.actions;

export default directorySlice.reducer;
