/* eslint-disable */
import React from 'react';
import { ReactComponent as Logo } from '@/assets/images/svg/mailavail-logo.svg';

const Preloader: React.FC = () => (
  <div className="fixed inset-0 flex justify-center items-center flex-col">
    <div className=" animate-bounce  ">
      <div className="animation-scale animate-pulse ">
        <Logo />
      </div>
    </div>
    {/* <div className=" drop-shadow-lg w-14 h-16  "></div> */}
  </div>
);

export default Preloader;
