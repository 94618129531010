/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { init, ErrorBoundary } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { GoogleOAuthProvider } from '@react-oauth/google';
import store from '@/config/store';
import Preloader from './components/PreLoader/preloader';
import './index.css';
import './config/i18n';
import { ThemeProvider } from './components/theme/themeContext';

const App = React.lazy(() => import('./App'));

// // Enable and disable Sentry due to unknown account

// if (process.env.REACT_APP_ENABLE_FB_SENTRY === 'true') {
//   init({
//     dsn: process.env.REACT_APP_SENTRY_DSN,
//     integrations: [new Integrations.BrowserTracing()],
//     tracesSampleRate: 1.0,
//   });
// }

const render = () => {
  ReactDOM.render(
    <ErrorBoundary fallback="An error has occurred">
      <Provider store={store}>
        <GoogleOAuthProvider clientId="657947449078-uip6h5intcrjaqm28td5pj214oqeajpf.apps.googleusercontent.com">
          <ThemeProvider initialTheme="dark">
            <React.Suspense fallback={<Preloader />}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </React.Suspense>
          </ThemeProvider>
        </GoogleOAuthProvider>
      </Provider>
    </ErrorBoundary>,
    document.getElementById('root'),
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render);
}
