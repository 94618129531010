import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

async function getClientIP(): Promise<string | null> {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip || null;
  } catch (error) {
    console.error('Error fetching IP address:', error);
    return null;
  }
}

export const fetchPricing = createAsyncThunk(
  'fetchPricing',
  async (credits: number) => {
    const clientIP = await getClientIP();
    if (!clientIP) {
      throw new Error('Unable to retrieve client IP.');
    }
    const response = await fetch(`${process.env.REACT_APP_API_URL}/pricing`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: clientIP, // Add your authorization header here
      },
      body: JSON.stringify({ credits }),
    });
    const data = await response.json();
    return data;
  },
);

const pricingSlice = createSlice({
  name: 'pricing',
  initialState: {
    isLoading: false,
    data: {},
    isError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPricing.pending, (state, ) => {
        state.isLoading = true;
      })
      .addCase(fetchPricing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchPricing.rejected, (state, ) => {
        state.isError = true;
      });
  },
});

export default pricingSlice.reducer;
