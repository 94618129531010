/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  data: {
    id?: number;
    user_id?: number;
    is_active?: boolean;
    type?: string;
    credits_purchased?: number;
    amount_usd?: number | null;
    price_per_credit?: number | null;
    stripe_payment_id?: string;
    customer_id?: string;
    transaction_date?: number;
    invoice_url?: string;
    start_date?: number;
    end_date?: number;
    created_at?: number;
    updated_at?: number;
    deleted_at?: number;
  };
  loading?: boolean;
};

const initialState: State = {
  data: {
    type: '',
  },
  loading: false,
};

const userPlan = createSlice({
  name: 'userPlan',
  initialState,
  reducers: {
    setUserPlan(state: State, action: PayloadAction<State>) {
      const { data, loading } = action.payload;
      const dataUserPlan = data || {};
      state.data = dataUserPlan;
      state.loading = loading || false;
    },
  },
});

export const { setUserPlan } = userPlan.actions;

export default userPlan.reducer;
